import request from '@/utils/request'
// 新建价格组
export function add(data) {
  return request({
    url: 'api/shop/priceGroup',
    method: 'post',
    data
  })
}

export function addMembers(data) {
  return request({
    url: 'api/shop/priceGroup/distributor',
    method: 'post',
    data
  })
}

export function delMember(data) {
  return request({
    url: 'api/shop/priceGroup/distributor',
    method: 'delete',
    data
  })
}
// 删除价格组
export function del(id) {
  return request({
    url: 'api/shop/priceGroup/' + id,
    method: 'delete'
  })
}
// 删除价格组
export function edit(data) {
  return request({
    url: 'api/shop/priceGroup',
    method: 'put',
    data
  })
}
